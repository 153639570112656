@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma-ExtraBoldItalic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma-HeavyItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma-SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('./Axiforma-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
}
